*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  
}

.container {
  width: 100%
}

@media (min-width: 640px) {
  .container {
    max-width: 640px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px
  }
}

.visible {
  visibility: visible
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.top-0 {
  top: 0px
}

.left-0 {
  left: 0px
}

.top-2 {
  top: 0.5rem
}

.right-0 {
  right: 0px
}

.z-50 {
  z-index: 50
}

.col-span-3 {
  grid-column: span 3 / span 3
}

.col-span-2 {
  grid-column: span 2 / span 2
}

.float-right {
  float: right
}

.m-4 {
  margin: 1rem
}

.m-2 {
  margin: 0.5rem
}

.m-auto {
  margin: auto
}

.m-7 {
  margin: 1.75rem
}

.m-0 {
  margin: 0px
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem
}

.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem
}

.-mx-3 {
  margin-left: -0.75rem;
  margin-right: -0.75rem
}

.my-11 {
  margin-top: 2.75rem;
  margin-bottom: 2.75rem
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem
}

.mr-4 {
  margin-right: 1rem
}

.ml-4 {
  margin-left: 1rem
}

.mt-2 {
  margin-top: 0.5rem
}

.mr-3 {
  margin-right: 0.75rem
}

.mb-2 {
  margin-bottom: 0.5rem
}

.ml-2 {
  margin-left: 0.5rem
}

.mb-6 {
  margin-bottom: 1.5rem
}

.mr-6 {
  margin-right: 1.5rem
}

.mt-3 {
  margin-top: 0.75rem
}

.mt-4 {
  margin-top: 1rem
}

.mb-7 {
  margin-bottom: 1.75rem
}

.mt-7 {
  margin-top: 1.75rem
}

.mr-7 {
  margin-right: 1.75rem
}

.ml-1 {
  margin-left: 0.25rem
}

.mt-11 {
  margin-top: 2.75rem
}

.mb-5 {
  margin-bottom: 1.25rem
}

.mt-8 {
  margin-top: 2rem
}

.mt-10 {
  margin-top: 2.5rem
}

.mt-12 {
  margin-top: 3rem
}

.mb-4 {
  margin-bottom: 1rem
}

.mb-9 {
  margin-bottom: 2.25rem
}

.mb-14 {
  margin-bottom: 3.5rem
}

.mt-6 {
  margin-top: 1.5rem
}

.mt-1 {
  margin-top: 0.25rem
}

.mt-16 {
  margin-top: 4rem
}

.mb-16 {
  margin-bottom: 4rem
}

.mb-20 {
  margin-bottom: 5rem
}

.mt-9 {
  margin-top: 2.25rem
}

.mt-14 {
  margin-top: 3.5rem
}

.mt-5 {
  margin-top: 1.25rem
}

.mb-1 {
  margin-bottom: 0.25rem
}

.mb-11 {
  margin-bottom: 2.75rem
}

.ml-6 {
  margin-left: 1.5rem
}

.mr-8 {
  margin-right: 2rem
}

.mr-2 {
  margin-right: 0.5rem
}

.mb-8 {
  margin-bottom: 2rem
}

.mr-0 {
  margin-right: 0px
}

.mr-5 {
  margin-right: 1.25rem
}

.mb-3 {
  margin-bottom: 0.75rem
}

.mr-10 {
  margin-right: 2.5rem
}

.ml-12 {
  margin-left: 3rem
}

.ml-0 {
  margin-left: 0px
}

.mt-20 {
  margin-top: 5rem
}

.mb-40 {
  margin-bottom: 10rem
}

.mr-11 {
  margin-right: 2.75rem
}

.ml-5 {
  margin-left: 1.25rem
}

.mb-10 {
  margin-bottom: 2.5rem
}

.ml-16 {
  margin-left: 4rem
}

.-mr-1 {
  margin-right: -0.25rem
}

.mb-0 {
  margin-bottom: 0px
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.inline-flex {
  display: inline-flex
}

.table {
  display: table
}

.grid {
  display: grid
}

.contents {
  display: contents
}

.hidden {
  display: none
}

.h-9 {
  height: 2.25rem
}

.h-4 {
  height: 1rem
}

.h-screen {
  height: 100vh
}

.h-full {
  height: 100%
}

.h-12 {
  height: 3rem
}

.h-8 {
  height: 2rem
}

.h-auto {
  height: auto
}

.max-h-full {
  max-height: 100%
}

.w-1\/2 {
  width: 50%
}

.w-full {
  width: 100%
}

.w-24 {
  width: 6rem
}

.w-64 {
  width: 16rem
}

.w-10 {
  width: 2.5rem
}

.w-4 {
  width: 1rem
}

.w-1\/3 {
  width: 33.333333%
}

.w-40 {
  width: 10rem
}

.w-screen {
  width: 100vw
}

.w-80 {
  width: 20rem
}

.w-9 {
  width: 2.25rem
}

.w-12 {
  width: 3rem
}

.w-60 {
  width: 15rem
}

.w-2\/3 {
  width: 66.666667%
}

.w-10\/12 {
  width: 83.333333%
}

.w-8 {
  width: 2rem
}

.w-auto {
  width: auto
}

.w-20 {
  width: 5rem
}

.w-16 {
  width: 4rem
}

.w-2\/5 {
  width: 40%
}

.w-3\/5 {
  width: 60%
}

.min-w-full {
  min-width: 100%
}

.max-w-lg {
  max-width: 32rem
}

.max-w-xs {
  max-width: 20rem
}

.max-w-sm {
  max-width: 24rem
}

.grow {
  flex-grow: 1
}

.border-collapse {
  border-collapse: collapse
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.cursor-pointer {
  cursor: pointer
}

.appearance-none {
  -webkit-appearance: none;
          appearance: none
}

.grid-flow-row {
  grid-auto-flow: row
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr))
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr))
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr))
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr))
}

.flex-row {
  flex-direction: row
}

.flex-col {
  flex-direction: column
}

.flex-wrap {
  flex-wrap: wrap
}

.items-start {
  align-items: flex-start
}

.items-end {
  align-items: flex-end
}

.items-center {
  align-items: center
}

.items-stretch {
  align-items: stretch
}

.justify-start {
  justify-content: flex-start
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.justify-around {
  justify-content: space-around
}

.justify-items-start {
  justify-items: start
}

.gap-4 {
  gap: 1rem
}

.gap-2 {
  gap: 0.5rem
}

.gap-y-9 {
  row-gap: 2.25rem
}

.gap-x-4 {
  column-gap: 1rem
}

.gap-y-6 {
  row-gap: 1.5rem
}

.gap-x-20 {
  column-gap: 5rem
}

.gap-y-10 {
  row-gap: 2.5rem
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse))
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)))
}

.overflow-hidden {
  overflow: hidden
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.rounded-xl {
  border-radius: 0.75rem
}

.rounded-lg {
  border-radius: 0.5rem
}

.rounded {
  border-radius: 0.25rem
}

.rounded-md {
  border-radius: 0.375rem
}

.rounded-none {
  border-radius: 0px
}

.rounded-b {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem
}

.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem
}

.border-2 {
  border-width: 2px
}

.border {
  border-width: 1px
}

.border-0 {
  border-width: 0px
}

.border-b {
  border-bottom-width: 1px
}

.border-t {
  border-top-width: 1px
}

.border-b-2 {
  border-bottom-width: 2px
}

.border-l-2 {
  border-left-width: 2px
}

.border-solid {
  border-style: solid
}

.border-dashed {
  border-style: dashed
}

.border-none {
  border-style: none
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity))
}

.border-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgb(191 219 254 / var(--tw-border-opacity))
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity))
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity))
}

.border-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgb(219 234 254 / var(--tw-border-opacity))
}

.border-green-200 {
  --tw-border-opacity: 1;
  border-color: rgb(187 247 208 / var(--tw-border-opacity))
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity))
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity))
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity))
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity))
}

.bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(96 165 250 / var(--tw-bg-opacity))
}

.bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 244 / var(--tw-bg-opacity))
}

.bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(74 222 128 / var(--tw-bg-opacity))
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity))
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity))
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity))
}

.bg-transparent {
  background-color: transparent
}

.bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity))
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops))
}

.from-blue-500 {
  --tw-gradient-from: #3b82f6;
  --tw-gradient-to: rgb(59 130 246 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.via-blue-600 {
  --tw-gradient-to: rgb(37 99 235 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to)
}

.to-blue-700 {
  --tw-gradient-to: #1d4ed8
}

.p-4 {
  padding: 1rem
}

.p-1 {
  padding: 0.25rem
}

.p-6 {
  padding: 1.5rem
}

.p-16 {
  padding: 4rem
}

.p-2 {
  padding: 0.5rem
}

.p-10 {
  padding: 2.5rem
}

.p-0 {
  padding: 0px
}

.p-8 {
  padding: 2rem
}

.p-3 {
  padding: 0.75rem
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem
}

.py-2\.5 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem
}

.px-11 {
  padding-left: 2.75rem;
  padding-right: 2.75rem
}

.pl-16 {
  padding-left: 4rem
}

.pt-7 {
  padding-top: 1.75rem
}

.pb-4 {
  padding-bottom: 1rem
}

.pt-5 {
  padding-top: 1.25rem
}

.pr-2 {
  padding-right: 0.5rem
}

.pb-8 {
  padding-bottom: 2rem
}

.pl-4 {
  padding-left: 1rem
}

.pr-7 {
  padding-right: 1.75rem
}

.pt-12 {
  padding-top: 3rem
}

.pl-3 {
  padding-left: 0.75rem
}

.pt-2 {
  padding-top: 0.5rem
}

.pb-7 {
  padding-bottom: 1.75rem
}

.pl-10 {
  padding-left: 2.5rem
}

.pr-14 {
  padding-right: 3.5rem
}

.pb-24 {
  padding-bottom: 6rem
}

.pb-16 {
  padding-bottom: 4rem
}

.pt-4 {
  padding-top: 1rem
}

.pt-6 {
  padding-top: 1.5rem
}

.pr-3 {
  padding-right: 0.75rem
}

.pl-6 {
  padding-left: 1.5rem
}

.pl-9 {
  padding-left: 2.25rem
}

.pr-6 {
  padding-right: 1.5rem
}

.pb-1 {
  padding-bottom: 0.25rem
}

.pb-9 {
  padding-bottom: 2.25rem
}

.pr-4 {
  padding-right: 1rem
}

.pb-2 {
  padding-bottom: 0.5rem
}

.pb-10 {
  padding-bottom: 2.5rem
}

.pr-10 {
  padding-right: 2.5rem
}

.pt-8 {
  padding-top: 2rem
}

.pb-14 {
  padding-bottom: 3.5rem
}

.pr-16 {
  padding-right: 4rem
}

.pb-6 {
  padding-bottom: 1.5rem
}

.pl-2 {
  padding-left: 0.5rem
}

.pr-8 {
  padding-right: 2rem
}

.pb-40 {
  padding-bottom: 10rem
}

.pb-12 {
  padding-bottom: 3rem
}

.pt-10 {
  padding-top: 2.5rem
}

.pt-16 {
  padding-top: 4rem
}

.pr-20 {
  padding-right: 5rem
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.align-top {
  vertical-align: top
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem
}

.font-bold {
  font-weight: 700
}

.font-semibold {
  font-weight: 600
}

.font-medium {
  font-weight: 500
}

.font-normal {
  font-weight: 400
}

.font-light {
  font-weight: 300
}

.uppercase {
  text-transform: uppercase
}

.lowercase {
  text-transform: lowercase
}

.italic {
  font-style: italic
}

.leading-tight {
  line-height: 1.25
}

.leading-normal {
  line-height: 1.5
}

.tracking-wide {
  letter-spacing: 0.025em
}

.tracking-wider {
  letter-spacing: 0.05em
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity))
}

.text-blue-900 {
  --tw-text-opacity: 1;
  color: rgb(30 58 138 / var(--tw-text-opacity))
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity))
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity))
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity))
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity))
}

.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity))
}

.text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgb(250 204 21 / var(--tw-text-opacity))
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity))
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity))
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity))
}

.text-red-800 {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity))
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity))
}

.underline {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline
}

.opacity-90 {
  opacity: 0.9
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-blue-500\/50 {
  --tw-shadow-color: rgb(59 130 246 / 0.5);
  --tw-shadow: var(--tw-shadow-colored)
}

.outline {
  outline-style: solid
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.transition {
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.duration-300 {
  transition-duration: 300ms
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

.hover\:-translate-y-0\.5:hover {
  --tw-translate-y: -0.125rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.hover\:-translate-y-0:hover {
  --tw-translate-y: -0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.hover\:border-blue-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(191 219 254 / var(--tw-border-opacity))
}

.hover\:bg-blue-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity))
}

.hover\:bg-blue-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(96 165 250 / var(--tw-bg-opacity))
}

.hover\:bg-red-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(153 27 27 / var(--tw-bg-opacity))
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.hover\:bg-blue-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity))
}

.hover\:bg-green-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 244 / var(--tw-bg-opacity))
}

.hover\:bg-red-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity))
}

.hover\:bg-green-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity))
}

.hover\:bg-blue-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(30 64 175 / var(--tw-bg-opacity))
}

.hover\:bg-green-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity))
}

.hover\:bg-red-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity))
}

.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity))
}

.hover\:bg-green-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity))
}

.hover\:bg-gradient-to-br:hover {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops))
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.hover\:text-blue-700:hover {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity))
}

.hover\:underline:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline
}

.focus\:z-10:focus {
  z-index: 10
}

.focus\:border-0:focus {
  border-width: 0px
}

.focus\:border-blue-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity))
}

.focus\:border-gray-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity))
}

.focus\:bg-white:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.focus\:ring-4:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus\:ring-red-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(252 165 165 / var(--tw-ring-opacity))
}

.focus\:ring-gray-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(243 244 246 / var(--tw-ring-opacity))
}

.focus\:ring-blue-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity))
}

.focus\:ring-blue-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity))
}

@media (prefers-color-scheme: dark) {
  .dark\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgb(75 85 99 / var(--tw-border-opacity))
  }

  .dark\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity))
  }

  .dark\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity))
  }

  .dark\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity))
  }

  .dark\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(37 99 235 / var(--tw-bg-opacity))
  }

  .dark\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(30 58 138 / var(--tw-bg-opacity))
  }

  .dark\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgb(229 231 235 / var(--tw-text-opacity))
  }

  .dark\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity))
  }

  .dark\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
  }

  .dark\:placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgb(156 163 175 / var(--tw-placeholder-opacity))
  }

  .dark\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .dark\:shadow-blue-800\/80 {
    --tw-shadow-color: rgb(30 64 175 / 0.8);
    --tw-shadow: var(--tw-shadow-colored)
  }

  .dark\:hover\:border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(107 114 128 / var(--tw-border-opacity))
  }

  .dark\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity))
  }

  .dark\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity))
  }

  .dark\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(75 85 99 / var(--tw-bg-opacity))
  }

  .dark\:hover\:bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(29 78 216 / var(--tw-bg-opacity))
  }

  .dark\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
  }

  .dark\:focus\:ring-red-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(153 27 27 / var(--tw-ring-opacity))
  }

  .dark\:focus\:ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(55 65 81 / var(--tw-ring-opacity))
  }

  .dark\:focus\:ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(30 64 175 / var(--tw-ring-opacity))
  }
}

@media (min-width: 640px) {
  .sm\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem
  }
}

@media (min-width: 768px) {
  .md\:mb-0 {
    margin-bottom: 0px
  }

  .md\:w-1\/2 {
    width: 50%
  }

  .md\:p-5 {
    padding: 1.25rem
  }
}
